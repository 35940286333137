import "./v4/ads/ad-manager.js";
import Cookies from "./v4/js.cookie.js";


window.Cookies = Cookies;
window.TWT_IS_SUBSCRIBER = Cookies.get('onaip_') === '';
window.TWT_CONTENT_ONLY = Cookies.get('onaip_tnetnoc_ylno') === '';
console.log('__CONTENT: User is subscriber?', TWT_IS_SUBSCRIBER);
console.log('__CONTENT: User is content-only?', TWT_CONTENT_ONLY);

function twt_add_subscriber_display_flags() {
    const sheet = document.styleSheets.item(0);
    sheet.insertRule('.twt-subscriber-hide{display: none;}', sheet.cssRules.length);
    sheet.insertRule('.twt-non-subscriber-hide{display: block;}', sheet.cssRules.length);
    console.debug('__CONTENT: Added subscriber display flags');
}

function twt_add_non_subscriber_display_flags() {
    const sheet = document.styleSheets.item(0);
    sheet.insertRule('.twt-subscriber-hide{display: block;}', sheet.cssRules.length);
    sheet.insertRule('.twt-non-subscriber-hide{display: none;}', sheet.cssRules.length);
    console.debug('__CONTENT: Added non-subscriber display flags');
}


if( TWT_IS_SUBSCRIBER ) {
    twt_add_subscriber_display_flags();
} else {
    twt_add_non_subscriber_display_flags();
}

function twt_add_content_only_display_flags() {
    const sheet = document.styleSheets.item(0);
    sheet.insertRule('.twt-content-only-hide{display: none;}', sheet.cssRules.length);
    sheet.insertRule('.twt-content-only-show{display: block;}', sheet.cssRules.length);
    console.debug('__CONTENT: Added content-only display flags');
}


if( TWT_CONTENT_ONLY ) {
    twt_add_content_only_display_flags();
}
